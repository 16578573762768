import React, { Component } from 'react'

class DropArea extends Component {
    state = {
        drag: false
    }
    dropRef = React.createRef()
    inputRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++

        this.setState({ drag: true })

    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--

        this.setState({ drag: false })

    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }
    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }
    render() {
        return (
            <>
                <input type={"file"} style={{ display: "none" }} ref={this.inputRef} onChange={(e)=>{    this.props.handleDrop(e.target.files) }} />
                <div className={'d-flex justify-content-center align-items-center drag-area ' + (this.state.drag ? "drag" : "")} onClick={() => { this.inputRef.current.click() }} ref={this.dropRef} >

                    <div>
                        {this.props.children}
                    </div>


                </div>
            </>

        )
    }
} export default DropArea