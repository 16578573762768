

export default function MyDeal({ product }) {
    return <>

        <div className="row">

            {
                product.map(item => {
                    return <>


                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div class='square-box w-100'>
                                        <div class='square-content' style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: "url('" + item.image_path + "')" }}>


                                        </div>
                                    </div>
                                    <h6 className="mt-4" style={{ height: 40, overflow: "hidden" }}>{item.product_name}</h6>
                                    <h6 className="text-muted" style={{ fontSize: "0.8rem", height: 30, overflow: "hidden" }}>{item.product_description}</h6>
                                    <a href={item.redirect_url} target="_blank">
                                        <div className="btn btn-primary w-100"> Bilgi Al</div>
                                    </a>




                                </div>
                            </div>

                        </div>
                    </>
                })
            }



        </div>

    </>
}