import axios from "axios";
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2";
import Helper from "../Helper";

export default function Test() {
    var navigate = useNavigate();
    // var params = useParams();
    // var { id } = params.query;
    const [page, setPage] = useState(0)
    const usernameRef = useRef();
    const passwordRef = useRef();
    const nameRef = useRef();
    const adddressRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const [show, setShow] = useState(false);



    function RegisterButton() {
        var insertData = {};
        console.log(emailRef.current.value);
        insertData.username = usernameRef.current.value;
        insertData.name = nameRef.current.value;
        insertData.address = adddressRef.current.value;
        insertData.phone = phoneRef.current.value;
        insertData.password = passwordRef.current.value;
        insertData.mail = emailRef.current.value;
        let json = JSON.stringify(insertData);
        axios.post("https://185.169.54.83:3005/auth/record", json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            console.log(r);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'İşlem Başarılı'
            })
            setShow(false);
            setPage(0);

        })
    }
    function LoginButton() {
        var login = {};
        login.username = usernameRef.current.value;
        login.password = passwordRef.current.value;
        let json = JSON.stringify(login);
        axios.post("https://185.169.54.83:3005/auth/login", json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            console.log(r);
            if (r.data.code !== 401) {
                if (r.data.code !== 0) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'İşlem Başarılı'
                    })
                    setShow(false);
                    localStorage.setItem("userData", JSON.stringify(r.data.res));
                    localStorage.getItem("userData");
                    navigate("/");
                } else {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'error',
                        title: 'Böyle bir kullanıcı bulunamadı'
                    })
                }
            } else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Bilgilerinizi kontrol ediniz'
                })
                // Swal.fire({
                //     icon: "error",
                //     title: "Bilgilerinizi kontrol ediniz"
                // })
                setShow(false);
            }
        })
    }
    function SendMail() {
        let data = { mail: emailRef.current.value };
        let json = JSON.stringify(data);
        axios.post("https://185.169.54.83:3005/auth/remember-password", json, {
            headers: { "Content-Type": "application/json" }
        }).then(res => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Mail Adresinize Şifre sıfırlama linki gönderildi. Mail adresinizi kontrol ediniz'
            })
            setPage(0);
        })
    }
    // useEffect(() => {
    //     console.log(id);
    // }, [id]);
    return <>

        <section className="min-vh-100 gradient-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-light text-white" style={{ borderRadius: '1rem' }}>
                            <div className="card-body p-5">
                                <ViewRender />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </>

    function ViewRender() {
        switch (page) {
            case 0:
                return <Login />
                break;
            case 1:
                return <Register />
                break;
            case 2:
                return <RememberPassword />
                break;
        }
    }


    function OnKeyDown(e) {
        if (e.key === 'Enter') {
            LoginButton()
        }

    }
    function Login() {
        return <>

            <div className="mb-md-5 pb-5">
                <div className="w-100 text-center d-flex justify-content-center" >
                    <img height={100} src="https://www.melis.com.tr/vendor/img/logo.svg" />
                </div>

                <h2 className="fw-bold mb-2 text-dark text-center w-100 mt-2">Giriş yap</h2>
                <p className="text-black-50 mb-5">Lütfen kullanıcı adı ve şifrenizle giriş yapınız</p>
                <div className="form-outline form-white mb-4">
                    <label className="form-label  text-dark" htmlFor="typeUsername">Kullanıcı Adı veya Telefon</label>
                    <input type="texts" id="typeUsername" className="form-control form-control-lg text-dark" ref={usernameRef} onKeyDown={(e) => { OnKeyDown(e, 1) }} />

                </div>
                <div className="form-outline form-black mb-4 text-left">
                    <label className="form-label  text-dark text-left " htmlFor="typePasswordX">Şifre</label>
                    <input type="password" id="typePasswordX" className="form-control form-control-lg" ref={passwordRef} onKeyDown={(e) => { OnKeyDown(e, 2) }} />

                </div>
                <p className="small pb-lg-2"><a className="text-dark" onClick={() => setPage(2)}>Şifremi unuttum?</a></p>
                <p className="small pb-lg-2"><a className="text-dark" onClick={() => setPage(3)}>Şifre Sıfırla</a></p>
                <button className="btn btn-outline-primary btn-lg px-5" type="submit" onClick={() => LoginButton()}>Giriş yap</button>


            </div>
            <div>
                <p className="mb-0 text-dark">Bir hesabın yok mu? <a onClick={() => setPage(1)} className="text-primary fw-bold">Kayıt ol</a>
                </p>
            </div>
        </>
    }
    function Register() {
        return <>
            <div className="mb-md-5 pb-5">
                <div className="w-100 text-center d-flex justify-content-center" >
                    <img height={100} src="https://www.melis.com.tr/vendor/img/logo.svg" />
                </div>

                <h2 className="fw-bold mb-2 text-uppercase text-dark text-center w-100 mt-2" >Kayıt ol</h2>
                <p className="text-black-50 mb-5">Lütfen kayıt olmak için formu doldurunuz</p>
                <div className="form-outline form-white mb-4">
                    <label className="form-label  text-dark" htmlFor="typeNameX">Firma adı</label>
                    <input type="text" id="typeNameX" className="form-control form-control-lg text-dark" ref={nameRef} />

                </div>
                <div className="form-outline form-white mb-4">
                    <label className="form-label  text-dark" htmlFor="typeUsernameX">Kullanıcı Adı</label>
                    <input type="text" id="typeUsernameX" className="form-control form-control-lg text-dark" ref={usernameRef} />

                </div>
                <div className="form-outline form-black mb-4 text-left">
                    <label className="form-label  text-dark text-left " htmlFor="typePasswordX">Şifre</label>
                    <input type="password" id="typePasswordX" className="form-control form-control-lg" ref={passwordRef} />

                </div>
                <div className="form-outline form-black mb-4 text-left">
                    <label className="form-label  text-dark text-left " htmlFor="typeAdresX">Adres</label>
                    <input type="text" id="typeAdresX" className="form-control form-control-lg" ref={adddressRef} />

                </div>
                <div className="form-outline form-black mb-4 text-left">
                    <label className="form-label  text-dark text-left " htmlFor="typePhone">Telefon</label>
                    <input type="number" id="typePhone" className="form-control form-control-lg" ref={phoneRef} />

                </div>
                <div className="form-outline form-black mb-4 text-left">
                    <label className="form-label  text-dark text-left " htmlFor="typeEmailX">EMail</label>
                    <input type="email" id="typeEmailX" className="form-control form-control-lg" ref={emailRef} />

                </div>
                <p className="small pb-lg-2"><a className="text-dark" onClick={() => setPage(0)}>Zaten bir hesabın mı var? Giriş Yap</a></p>
                <button className="btn btn-outline-primary btn-lg px-5" type="submit" onClick={() => RegisterButton()}>Kayıt ol</button>


            </div>
            {/* <div>
                <p className="mb-0 text-dark">Bir hesabın yok mu? <a onClick={() => setPage(1)} className="text-primary fw-bold">Kayıt ol</a>
                </p>
            </div> */}
        </>
    }
    function RememberPassword() {
        return <>
            <div className="mb-md-5 pb-5">
                <div className="w-100 text-center d-flex justify-content-center" >
                    <img height={100} src="https://www.melis.com.tr/vendor/img/logo.svg" />
                </div>

                <h2 className="fw-bold mb-2 text-uppercase text-dark text-center w-100 mt-2">Şifremi unuttum</h2>
                <p className="text-black-50 mb-5">Kayıtlı mail adresinize yeni şifreniz iletilecektir.</p>
                <div className="form-outline form-white mb-4">
                    <label className="form-label  text-dark" htmlFor="typeEmailX">Mail adresi</label>
                    <input type="email" id="typeEmailX" className="form-control form-control-lg text-dark" ref={emailRef} />

                </div>

                <p className="small pb-lg-2"><a className="text-dark" onClick={() => setPage(0)}>Zaten bir hesabın mı var? Giriş Yap</a></p>
                <button className="btn btn-outline-primary btn-lg px-5" type="submit" onClick={() => SendMail()}>Mail Gönder</button>


            </div>
            <div>
                <p className="mb-0 text-dark">Bir hesabın yok mu? <a onClick={() => setPage(1)} className="text-primary fw-bold">Kayıt ol</a>
                </p>
            </div>
        </>
    }

}