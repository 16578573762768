import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { SetItemNext } from "../Redux/playListSlice";

export default function Plainer() {
    const playList = useSelector(state => state.playList.list);
    const dispatch = useDispatch();
    let [timerClock, setTimerClock] = useState(0);

    useEffect(() => {
        const timer = setInterval(function () {

            playList.map(it=>{
              
                var playTime = new Date();
                playTime.setTime(it.playTime);

                var now = new Date();

                if(now > playTime)
                {
                    
                    global.PlaySound("https://185.169.54.83:3005/" + it.path)
                    dispatch(SetItemNext(it))
                }
                else
                {
                    console.log(now);
                    console.log(playTime);
                }
            })
            
            setTimerClock(timerClock + 1);
        }, 1000)

        return () => { // this runs as the clean up function for the useEffect
            clearInterval(timer)
        }


    }, [timerClock]);

    return <>
    
    </>
}