import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal'
import 'swiper/css';

// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';
import RadioArea from './RadioArea';
import ActivePlain from './ActivePlain';
import AllPlain from './AllPlain';
import MyFiles from './MyFiles';
import Demands from './Demands';
import MyDeal from './MyDeal';
import axios from 'axios';
import Promotion from './Promotion';
import { useNavigate } from "react-router-dom"
import { useRef } from 'react';
import Swal from 'sweetalert2';
import Helper from '../Helper';
import ResetPassword from './ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { SetItemNext } from '../Redux/playListSlice';

export default function Index() {


    const playList = useSelector(state=>state.playList.list);


    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [update, setUpdate] = useState(false);
    const [product, setProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    var inputImageRef = useRef();
    const [selectedImage, setSelectedImage] = useState();

    const [userData, setUserData] = useState()
    const [photo, setPhoto] = useState();

    const inputName = useRef();

    var companyId = Helper().companyId;
    useEffect(() => {

        setUserData(Helper().useData);
        axios.get("https://185.169.54.83:3005/company/product").then(r => {
            setProduct(r.data)
        })
        setPhoto(userData?.company_profile_imag);

    }, [])
    useEffect(() => {
        setPhoto(userData?.company_profile_imag);

    }, [userData]);
    const [putRes, setPutRes] = useState();

    const dispatch = useDispatch();
    function PlayNow(id) {
        console.log(playList)
        playList.map(it => {
            if (it.play_list_id == id) {
             
                global.PlaySound("https://185.169.54.83:3005/" + it.path)
                dispatch(SetItemNext(it))
            }
        })

    }

    function imageChange(e) {

        setSelectedImage(e.target.file)
        var formData = new FormData();
        console.log(e.target);
        formData.append("profileIcon", e.target.files[0]);
        axios.put("https://185.169.54.83:3005/auth/" + companyId, formData, { headers: { "Content-Type": "application/json" } }).then(res => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'İşlem Başarılı'
            })
            setPutRes(res.data);
            let data = userData;
            data.company_profile_imag = res.data.res;
            localStorage.setItem("userData", JSON.stringify(data));

            setShowSuccess(false);
        })
    }
    useEffect(() => {
        setPhoto(putRes?.res);
    }, [putRes]);

    const input = useRef();
    function RendererScreen() {
        if (selectedIndex == 0) {
            return <ActivePlain listener={() => { setSelectedIndex(2) }} />
        }
        else if (selectedIndex == 1) {
            return <Demands />
        }
        else if (selectedIndex == 2) {
            return <MyFiles />
        }
        else if (selectedIndex == 3) {
            return <MyDeal product={product} />
        }
        else if (selectedIndex == 4) {
            return <ResetPassword />
        }
        return <></>
    }
    function UpdateCompanyName() {
        if (update) {
            let data = {};
            data.companyName = inputName.current.value;
            let json = JSON.stringify(data);
            axios.put("https://185.169.54.83:3005/company/field/name/" + companyId, json, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.data.code === 1) {




                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'İşlem Başarılı'
                    })
                    let d = userData;
                    d.company_name = data.companyName;
                    localStorage.setItem("userData", JSON.stringify(d));
                    setUpdate(false);
                }
            })
        } else {


            inputName.current.value = userData?.company_name;
        }

    }
    return <>
        <div className="container" style={{ maxWidth: 1400 }}>
            {
                Helper().control ?
                    <div className='d-flex justify-content-end mt-5 '><div className='btn-primary btn-sm me-2' onClick={() => { navigate("/auth"); localStorage.clear() }} style={{ fontSize: "0.8rem" }}>Çıkış Yap</div></div> : <div className='d-flex justify-content-end mt-5 '>
                        <div className='btn-primary btn-sm me-2' onClick={() => navigate("/auth")} style={{ fontSize: "0.8rem" }}>Giriş yap</div>
                        <div className='btn btn-success btn-sm' onClick={() => navigate("/auth")} style={{ borderRadius: "20px" }}>Kayıt Ol</div>
                    </div>
            }

            <br />
            <br />
            <br />
            <div className="row">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-header d-flex mx-4 px-4">
                            <div onClick={() => { input.current.click() }} className='compnay-logo d-flex justify-content-center align-items-center' style={{ position: "relative" }}>
                                <div className='d-flex align-items-center justify-content-center' style={{ fontSize: 10, position: "absolute", right: 10, bottom: 10, width: 30, height: 30, zIndex: 999, backgroundColor: "white", boxShadow: "#000000 0px 0px 5px", borderRadius: 50 }}>
                                    ✏️
                                </div>
                                <img src={photo == "" || photo == undefined ? "https://i.pinimg.com/736x/5e/c7/c9/5ec7c909bc51ee9b9c6a6125d634fc8c.jpg" : "https://185.169.54.83:3005/images/" + photo} style={{ borderRadius: 500,width : "100%",height:"100%",objectFit : "cover" }} />

                                <input ref={input} value={selectedImage} onChange={(e) => imageChange(e)} type="file" style={{ position: "absolute", display: "none", justifyContent: "center" }} />

                            </div>
                            <div className='mx-4'>
                                <div>


                                    {
                                        update ? <input ref={inputName} defaultValue={userData?.company_name} className='col-form-label-sm input' /> : <h3>{userData?.company_name}</h3>
                                    }
                                    <button className='btn btn-primary' onClick={() => { setUpdate(!update); UpdateCompanyName() }}>Güncelle</button>
                                    <button className='btn btn-outline-primary mx-2' onClick={() => { PlayNow(-2) }}>🔊 Jingle</button>
                                    <div className=''>
                                        

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="card-body px-4 mx-4">
                            <br />
                            <br />
                            <ul class="list-inline">
                                <li className={"list-inline-item " + (selectedIndex == 0 ? "active" : "")} onClick={() => { setSelectedIndex(0) }}>
                                    Çalma listem
                                </li>
                                <li className={"list-inline-item " + (selectedIndex == 1 ? "active" : "")} onClick={() => { setSelectedIndex(1) }}>
                                    İndirim ses kayıt taleplerim
                                </li>

                                <li className={"list-inline-item " + (selectedIndex == 3 ? "active" : "")} onClick={() => { setSelectedIndex(3) }}>
                                    Melis'ten öne çıkanlar
                                </li>
                                <li className={"list-inline-item " + (selectedIndex == 4 ? "active" : "")} onClick={() => { setSelectedIndex(4) }}>
                                    Şifre Güncelle
                                </li>

                            </ul>
                            <br />

                            <RendererScreen />


                        </div>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className='card mb-4'>
                        <div className='card-body'>
                            <img src='https://www.melis.com.tr/vendor/img/logo.svg' className='img-fluid w-100' />
                        </div>
                    </div>
                    <RadioArea />
                    <Promotion />

                </div>
            </div>

        </div>
        <Modal show={show} onHide={() => { setShow(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Hemen Çal</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <table className='w-100'>
                    <tr>
                        <th>#</th>
                        <th>İsim</th>
                        <th>Durum</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Ramazan Coco Cola Kampanyası</td>
                        <td style={{ maxWidth: "50px" }}>
                            <div className='btn btn-outline-primary'>Çal</div>
                        </td>
                    </tr>
                </table>
            </Modal.Body>

        </Modal>


    </>


}
//