import axios from 'axios';
import React from 'react'
import { useRef } from 'react';
import Swal from 'sweetalert2';
import Helper from '../Helper';

function ResetPassword() {
  const oldPasswordRef = useRef();
  const newPasswordConfirmRef = useRef();
  const newPasswordRef = useRef();
  var resetData = {};
  var companyId = Helper().companyId;
  function ResetPasswordButton() {


    resetData.oldPassword = oldPasswordRef.current.value;
    resetData.newPassword = newPasswordRef.current.value;
    resetData.newPasswordConfirm = newPasswordConfirmRef.current.value;
    var json = JSON.stringify(resetData);
    if (newPasswordRef.current.value === newPasswordConfirmRef.current.value) {
      console.log("aloooo");
      axios.put("https://185.169.54.83:3005/auth/reset-password/" + companyId, json,
        { headers: { "Content-Type": "application/json" } }).then(res => {
          console.log(res);
          if (res.data.code === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'Şifre güncelleme işlemi başarılı'
            })
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'error',
              title: 'Bilgilerinizi kontrol ediniz'
            })
          }
        })
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'error',
        title: 'Girdiğiniz yeni şifreler uyuşmuyor'
      })
    }
  }
  return <>
    <div className="mb-md-5 pb-5">
      <div className="w-100 text-center d-flex justify-content-center" >
        <img height={100} src="https://www.melis.com.tr/vendor/img/logo.svg" />
      </div>

      <h2 className="fw-bold mb-2 text-uppercase text-dark text-center w-100 mt-2">Şifre Sıfırlama</h2>
      {/* <p className="text-black-50 mb-5">Kayıtlı mail adresinize yeni şifreniz iletilecektir.</p> */}
      <div className="form-outline form-black mb-4 text-left">
        <label className="form-label  text-dark text-left " htmlFor="typePasswordX">Eski Şifre</label>
        <input type="password" id="typePasswordX" className="form-control form-control-lg" ref={oldPasswordRef} />

      </div>
      <div className="form-outline form-black mb-4 text-left">
        <label className="form-label  text-dark text-left " htmlFor="typePasswordX">Yeni Şifre</label>
        <input type="password" id="typePasswordX" className="form-control form-control-lg" ref={newPasswordRef} />

      </div>
      <div className="form-outline form-black mb-4 text-left">
        <label className="form-label  text-dark text-left " htmlFor="typePasswordX">Şifre Tekrar</label>
        <input type="password" id="typePasswordX" className="form-control form-control-lg" ref={newPasswordConfirmRef} />

      </div>

      {/* <p className="small pb-lg-2"><a className="text-dark" onClick={() => setPage(0)}>Zaten bir hesabın mı var? Giriş Yap</a></p> */}
      <button className="btn btn-outline-primary btn-lg px-5" onClick={() => ResetPasswordButton()}>Sıfırla</button>


    </div>
  </>
}

export default ResetPassword