import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import Helper from '../Helper';


export const FetchPlayList = createAsyncThunk(
    'playList/fetch',
    async (thunkAPI) => {
        var r = await axios.get("https://185.169.54.83:3005/company/play_list/" + Helper().companyId);
        var dataList = [];

        r.data.map(it => {
            var today = new Date();
            var minute = it.squance.split(":")[1];

            today.setTime(today.getTime() + minute * 60000)

            it.playTime = today.getTime()
                ;
            dataList.push(it);
        })
        return dataList;
    }
)


export const playListSlice = createSlice({
    name: 'playList',
    initialState: {
        list: [],
    },
    reducers: {
        SetItemNext: (state, action) => {

            state.list.map(it => {
                console.log(it);
                console.log(action.payload);
                if (it.play_list_id == action.payload.play_list_id) {
                    var today = new Date();
                    var minute = it.squance.split(":")[1];

                    today.setTime(today.getTime() + minute * 60000)

                    it.playTime = today.getTime()
                }
            })

        },

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(FetchPlayList.fulfilled, (state, action) => {

            var fakeList = action.payload;
            var today = new Date();


            fakeList.push({
                "play_list_id": -2,
                "item_name": "Melis Jingle",
                "path": "/sound/b.mp3",
                "squance": "00:30:00",
                "item_creator_id": 9,
                "is_delete": 0,
                "playTime": today.getTime() + 30 * 6000000
            })
            fakeList.push({
                "play_list_id": -1,
                "item_name": "Melis Reklam",
                "path": "sound/a.mp3",
                "squance": "00:15:00",
                "item_creator_id": 9,
                "is_delete": 0,
                "playTime": today.getTime() + 15 * 6000000
            })
            state.list = fakeList;
        })
    },
})

// Action creators are generated for each case reducer function
export const { SetItemNext } = playListSlice.actions

export default playListSlice.reducer