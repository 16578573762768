import axios from "axios";
import { useState } from "react";
import DropArea from "../Component/DropArea";
import Swal from "sweetalert2"
import { useRef } from "react";
import Helper from "../Helper";
import { useDispatch } from "react-redux";
import { FetchPlayList } from "../Redux/playListSlice";

export default function MyFiles({ listener }) {
    var companyId = Helper().companyId;
    const [files, setFiles] = useState();
    const inputTittle = useRef();
    function handleDrop(files) {

        setFiles(files)
    }

    const dispatch = useDispatch();
    function UploadFiles() {
        const formData = new FormData();
        formData.append("sound", files[0]);
        formData.append("squance", "00:20");
        formData.append("item_name", inputTittle.current.value);

        axios.post("https://185.169.54.83:3005/company/play_list/" + companyId, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(r => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: 'Harika! Ses dosyası sisteme yüklendi. Artık bunu çalabilirsin'
            }).then(r => {
                dispatch(FetchPlayList());

            })
            setFiles(undefined)
            listener();
        })


    }

    return <>

        <div class="form-group mb-4">
            <label for="exampleFormControlInput1">Talep Başlığı</label>
            <input type="email" class="form-control" placeholder='Bu parçanın adı' ref={inputTittle} />
        </div>
        {
            files != undefined ? <>
                <table className="w-100">
                    <tr>
                        <th>#</th>
                        <th>Parça Adı</th>
                        <th>Eylem</th>
                    </tr>
                    <tr>
                        <td>#</td>
                        <td>{files[0].name}</td>
                        <td><div className="btn btn-primary" onClick={() => { UploadFiles() }}>Yükle</div></td>
                    </tr>
                </table>
            </> : <></>
        }



        <DropArea handleDrop={handleDrop} >
            Bu alana tıklayarak ya da yüklemek istediğiniz ses dosyasını sürükleyerek yükleyebilirsiniz
        </DropArea >
    </>
}