import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Index from './View/Index';
import 'bootstrap/dist/css/bootstrap.css'
import './Assets/css/cactus.css'
import './Assets/css/drop.css'
import './Assets/css/square.css'
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate, } from 'react-router-dom';
import Test from './View/Tes';
import Helper from './Helper';
import { Provider, useDispatch } from 'react-redux';
import { FetchPlayList } from './Redux/playListSlice';
import store from './Redux/store';
import Plainer from './View/Planier';
const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Plainer />
    <BrowserRouter>
      <MainPAth />
    </BrowserRouter>
    {/* <Route exact path='/login' element={<Login />} />
        <Route exact path='/register' element={<Register />} /> */}
  </Provider>

);
function MainPAth() {
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {

    var data = localStorage.getItem('userData');
    if (data != undefined && data != null && data != 'undefined' && data != '') {
      dispatch(FetchPlayList())
      history('/')
    }
    else {

      history('/auth')
    }
  }, [])
  return <>
    <Routes>
      <Route exact path='/' element={<Index />} />
      <Route path='/auth' element={<Test />} />
    </Routes>
  </>
}