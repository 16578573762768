
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCube } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cube';

import 'swiper/css/navigation';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Promotion() {


    const [sliderList, setSliderList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(0);
   
    const [product, setProduct] = useState([]);
    
    useEffect(() => {
        axios.get("https://185.169.54.83:3005/company/slider").then(r => {
            setSliderList(r.data)
        })
       
    }, [])

    return <>

        <div className='card mt-4'>
            <div className='card-body'>
                <h3>Öne Çıkan Ürünler</h3>

                {
                    sliderList.length == 0 ? <></> : <>
                        <Swiper
                            modules={[Navigation, Pagination, A11y, Autoplay, EffectCube]}
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={true}
                            effect={'cube'}

                            onChange={(e) => {
                                console.log(e)
                            }}

                            pagination={{ clickable: true }}

                            onSlideChange={(e) => setSelectedProduct(e.activeIndex)}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                sliderList.map(it => {
                                    return <SwiperSlide>
                                        <img src={it.image_path} className='w-100 img-fluid' />


                                    </SwiperSlide>
                                })
                            }



                        </Swiper>
                        <div className='mt-4'>

                            <h3>{sliderList[selectedProduct].product_name}</h3>
                            <div style={{ height: 50, overflow: "hidden" }}>{sliderList[selectedProduct].product_description}</div>
                        </div>

                    </>
                }

            </div>
        </div>



    </>
}