export default function Helper() {
  function getCompanyId() {
    var data = localStorage.getItem('userData');
    if (data != undefined && data != '' && data != 'null' & data != 'undefined') {
      return JSON.parse(localStorage.getItem('userData')).company_id
    }
    else {
      return null
    }
  }
  function getUserData() {
    var data = localStorage.getItem('userData');
    if (data != undefined && data != '' && data != 'null' & data != 'undefined') {
      return JSON.parse(data)
    }
    else {
      return null
    }
  }
  function userDataControl() {
    var data = localStorage.getItem('userData');
    if (data != undefined && data != '' && data != 'null' & data != 'undefined') {
      return true
    }
    else {
      return false
    }
  }
  return {
    useData: getUserData(),
    companyId: getCompanyId(),
    control: userDataControl()
  }
}