import TimePicker from 'react-time-picker';

import Modal from 'react-bootstrap/Modal'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import MyFiles from './MyFiles';
import Helper from '../Helper';
import { useDispatch, useSelector } from 'react-redux';
import { FetchPlayList, SetItemNext } from '../Redux/playListSlice';

export default function ActivePlain({ listener }) {
    const companyId = Helper().companyId;


    const playList = useSelector(state => state.playList.list);
    function onTimeChange(options) {
        // do something
    }
    function onFocusChange(focusStatue) {
        // do something
    }
    const [show2, setShow2] = useState(false);


    const [value, onChange] = useState('10:00');





    const dispatch = useDispatch()

    function PlayNow(item) {
        playList.map(it => {
            if (it.play_list_id == item.play_list_id) {
                
                global.PlaySound("https://185.169.54.83:3005/" + it.path)
                dispatch(SetItemNext(it))
            }
        })

    }

    function RemoveItem(id) {

        axios.delete("https://185.169.54.83:3005/company/play_list/" + id).then(r => {
            dispatch(FetchPlayList())
        })
    }
    function UpdateData(id, value) {
        axios.put("https://185.169.54.83:3005/company/play_list/" + id, { "squance": value }).then(r => {
            dispatch(FetchPlayList())
        })
    }

    return <>
        <div class="d-flex justify-content-between">
            <h3> Çalma Listem</h3>
            <div>
                <div className='btn btn-primary' onClick={() => { setShow2(true) }}>
                    Yeni bir parça ekle
                </div>
            </div>
        </div>
        <span className='text-muted' style={{ fontSize: "0.8rem" }}>
            Bu ekran içerisinde marketinizde hangi saatlerde hangi indirim anonslarının çalmasını istediğinizi
            belirleyebilir farklı günler içinde bunları tekrar tekrar kullanabilirsiniz.
            Seçmiş olduğunuz saat aralıklarında devreye girecek kampanya anonsu sırasında radyo otomatik olarak geçici devre dışı kalacak anons bitimi tekrardan devreye girecektir.
        </span>
        <br />     <br />
        <table className='w-100 px-4 mx-4'>
            <tr>
                <th>Kayıt Adı</th>
                <th style={{ maxWidth: 100 }}>Çalma sıklığı</th>
                <th style={{ maxWidth: 100 }}>Çalma zamanı</th>
                <th>Eylemler</th>
            </tr>

            {
                playList?.map(it => {
                    if(it.play_list_id > 0)
                    {
                        return <>

                        <tr>
                            <td>{it.item_name}</td>

                            <td style={{ maxWidth: 100 }}>
                                <div style={{ width: 160 }}>
                                    <select value={it.squance} className='btn btn-outline-danger' onChange={(e) => { UpdateData(it.play_list_id, e.target.value) }}>
                                        <option value={"00:01:00"}>00:01:00</option>
                                        <option value={"00:02:00"}>00:02:00</option>
                                        <option value={"00:15:00"}>00:15:00</option>
                                        <option value={"00:30:00"}>00:30:00</option>
                                        <option value={"00:45:00"}>00:45:00</option>
                                        <option value={"01:00:00"}>01:00:00</option>
                                    </select>
                                </div>


                            </td>
                            <td>
                                {
                                    new Date(it.playTime).toLocaleTimeString()
                                }
                            </td>
                            <td>
                                {
                                    it.play_list_id < 0 ? <></> : <div className={'btn btn-outline-danger'} onClick={() => { RemoveItem(it.play_list_id) }} > Sil </div>
                                }

                                <div className='btn btn-outline-danger mx-2' onClick={() => { PlayNow(it) }} > 🔊 Oynat </div>
                            </td>
                        </tr>
                    </>
                    }
                 
                })
            }


        </table>
        <Modal show={show2} onHide={() => { setShow2(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Listeye bir parça ekle</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <MyFiles listener={() => {
                    setShow2(false);
                    axios.get("https://185.169.54.83:3005/company/play_list/" + companyId).then(r => {
                        dispatch(FetchPlayList())
                    })
                }} />
            </Modal.Body>

        </Modal>
    </>
}