
import Modal from 'react-bootstrap/Modal'

import { useEffect, useRef, useState } from "react";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from 'axios';
import Helper from '../Helper';
export default function RadioArea() {

    const [show, setShow] = useState(false);
    const [selectedSong, setSelectedSong] = useState("");
    var companyId = Helper().companyId;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [radioList, setRadioList] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState();

    const [selectedRadioIndex, setSelectedRadioIndex] = useState(0);
    const player = useRef(null)

 

    function PlaySound(soundPath) {
        setSelectedSong(soundPath)
    }
  
    


    useEffect(() => {

        global.PlaySound = PlaySound;


        axios.get("https://185.169.54.83:3005/company/radio").then(r => {
            setRadioList(r.data)
            setSelectedRadioIndex(r.data[0].radio_id)
            setSelectedSong(r.data[0].radio_path)
            setSelectedRadio(r.data[0])
        })
    }, [])

    function SelectRadio(id) {
        setSelectedRadioIndex(id)
        var deneme = radioList.find(it => { return it.radio_id == id })
        setSelectedRadio(deneme)
        setSelectedSong(deneme.radio_path)
    }
    return <>
        <div className="card">
            <div className='card-header'>
                <div class="d-flex justify-content-between">
                    <div>
                        Radyo Kanalı
                    </div>
                    <div>
                        <div className='btn btn-primary' onClick={() => { setShow(true) }}>Kanal Değiştir</div>
                    </div>
                </div>

            </div>
            <div className='card-body'>
                {
                    selectedRadio == undefined ? <>Yükleniyor</> : <>
                        <h3>
                            {
                                selectedRadio.radio_name
                            }
                        </h3>
                        <div className='d-flex'>
                            <img style={{ maxWidth: "100px" }} src={selectedRadio.image_path} className='img-fluid' />
                            <AudioPlayer
                                ref={player}
                                volume={1}
                                autoPlay={true}
                                src={selectedSong}
                                onPlay={e => console.log("onPlay")}
                                onEnded={e => {
                                    setSelectedSong(selectedRadio.radio_path)
                                }}

                            // other props here
                            />

                        </div>
                    </>
                }


            </div>



        </div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Radyo Kanalları</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <table className='w-100'>
                    <tr>
                        <th>#</th>
                        <th>İsim</th>
                        <th>Durum</th>
                    </tr>
                    {
                        radioList.map(it => {
                            return <tr>
                                <td>{it.radio_id}</td>

                                <td>{it.radio_name}</td>
                                <td style={{ maxWidth: "50px" }}>
                                    {
                                        selectedRadioIndex == it.radio_id ? <div className='btn btn-outline-primary'>Seçili</div> : <div className='btn btn-primary' onClick={() => { SelectRadio(it.radio_id) }}>Seç</div>
                                    }

                                </td>
                            </tr>
                        })
                    }

                </table>
            </Modal.Body>
            <Modal.Footer>


            </Modal.Footer>
        </Modal>
    </>
}