
import axios from 'axios';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Swal from "sweetalert2";
import Helper from '../Helper';

export default function Demands() {
    const [showDetail, setShowDetail] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState();
    const inputTittle = useRef();
    const inputDescription = useRef();
    const [detail, setDetail] = useState();

    var companyId = Helper().companyId;
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get("https://185.169.54.83:3005/company/request/detail/" + selectedRequestId).then(res => {
            console.log(res);
            setDetail(res.data);
        })
    }, [selectedRequestId]);

    useEffect(() => {
        axios.get("https://185.169.54.83:3005/company/request/" + companyId).then(r => {
            setData(r.data);
        })
    }, [])

    useEffect(() => {
        axios.get("https://185.169.54.83:3005/company/request/" + companyId).then(r => {
            setData(r.data);
        })
    }, [companyId])

    function GetStatus(data) {
        switch (data) {
            case 0:
                return "Bekliyor"
                break;
            case 1:
                return "Cevaplandı"
                break;
        }
    }

    function InsertData() {

        axios.post("https://185.169.54.83:3005/company/request/" + companyId, {
            tittle: inputTittle.current.value,
            description: inputDescription.current.value,
        }).then(r => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            axios.get("https://185.169.54.83:3005/company/request/" + companyId).then(r => {
                setData(r.data);
            })
            Toast.fire({
                icon: 'success',
                title: 'Harika! talebini aldık en kısa zamanda seninle iletişime geçeceğiz.'
            })
            setShow(false);

        })


    }


    return <>
        <div class="d-flex justify-content-between">
            <h3>Taleplerim</h3>


            <div>
                <div className='btn btn-primary' onClick={() => { setShow(true) }} >


                    Yeni bir talep oluştur
                </div>
            </div>
        </div>
        <table className='w-100'>
            <tr>
                <td>#</td>
                <td>Talep Başlığı</td>

                <td className='px-4'>Tarih</td>
                <td className='px-4'>Durum</td>
                <td>Eylem</td>
            </tr>



            {
                data.map(it => {
                    return <>
                        <tr>
                            <td>1</td>
                            <td style={{ fontSize: "0.8rem" }}>{it.request_tittle}</td>
                            <td className='px-4'>{it.requestDate}</td>
                            <td className='px-4'>{GetStatus(it.status)}</td>
                            <td><div className='btn btn-primary' onClick={() => { setShowDetail(true); setSelectedRequestId(it.request_id) }}>Görüntüle</div></td>
                        </tr>
                    </>
                })
            }

        </table>

        <Modal show={showDetail} onHide={() => { setShowDetail(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Talep</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div class="form-group">
                    <label for="exampleFormControlInput1" style={{ marginRight: "5px" }}>Talep Başlığı:  </label>
                    <div>{detail?.request_tittle}</div>
                </div>

                <div class="form-group mt-4">
                    <label style={{ marginRight: "5px" }} for="exampleFormControlInput1">Talep Açıklaması:  </label>
                    <p>{detail?.request_description}</p>
                </div>
                <div>
                    <label style={{ marginRight: "5px" }} for="exampleFormControlInput1">İstek Durumu:   </label>
                    {GetStatus(detail?.status)}
                </div>
                <div>
                    <label style={{ marginRight: "5px" }} for="exampleFormControlInput1">Cevap:   </label>
                    {detail?.response}
                </div>
                <div>
                    <label style={{ marginRight: "5px" }} for="exampleFormControlInput1">İstek Tarihi:   </label>
                    {detail?.requestDate}
                </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <div className='btn btn-primary' onClick={() => { InsertData() }}> Oluştur</div>

            </Modal.Footer> */}
        </Modal>


        <Modal show={show} onHide={() => { setShow(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Radyo Kanalları</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div class="form-group">
                    <label for="exampleFormControlInput1">Talep başlığı</label>
                    <input type="email" class="form-control" placeholder='Talebin başlığı' ref={inputTittle} />
                </div>

                <div class="form-group mt-4">
                    <label for="exampleFormControlInput1">Talep açıklaması</label>
                    <textarea ref={inputDescription} type="email" class="form-control" placeholder='Sizin için özel olarak çekilecek olan ses kaydının içeriği hakkında bilgilendirme yazınız' >
                    </textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='btn btn-primary' onClick={() => { InsertData() }}> Oluştur</div>

            </Modal.Footer>
        </Modal>
    </>


}